import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchContactGroup } from "./../../data/contactGroup/contactGroupSlice";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const ContactUsGroup = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { contactGroup, loading, error } = useSelector(
    (state) => state.contactGroup
  );
  useEffect(() => {
    dispatch(fetchContactGroup());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {contactGroup.pageDetails?.pageMetatagTitle
                ? contactGroup.pageDetails?.pageMetatagTitle
                : "Hotels"}
            </title>
            <meta
              name="description"
              content={
                contactGroup.pageDetails?.pageMetatagDescription
                  ? contactGroup.pageDetails?.pageMetatagDescription
                  : ""
              }
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={
              contactGroup.pageDetails?.pageBannerColorOverlayTo
            }
            style={{
              backgroundImage: `url(${
                isMobile
                  ? contactGroup.pageDetails?.pageBannerMobile
                  : isTablet
                  ? contactGroup.pageDetails?.pageBannerTablet
                  : contactGroup.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    <h5>
                      <b>Get in touch</b>{" "}
                    </h5>
                    <h1>{contactGroup.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Contact Us
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="py-5 my-5">
            {" "}
            <div className="container">
              <div className="row text-center mb-60">
                <div className="col-md-12 mb-60">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h3>{contactGroup.pageDetails?.pageTitle}</h3>
                    <p>{contactGroup.pageDetails?.pageText}</p>
                  </Fade>
                  <Fade triggerOnce direction="up">
                    <div className="d-flex flex-wrap justify-content-around">
                      {" "}
                      <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                        <div className="icon">
                          <span className="flaticon-call"></span>
                        </div>
                        <div className="text">
                          <p className="mb-2">Reservation </p>{" "}
                          <Link
                            className="fs-6"
                            to={`tel:${contactGroup.contactUsInfo?.groupContactUstelephone}`}
                          >
                            {
                              contactGroup.contactUsInfo
                                ?.groupContactUstelephone
                            }
                          </Link>
                        </div>
                      </div>
                      <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                        <div className="icon">
                          <span className="flaticon-envelope"></span>
                        </div>
                        <div className="text">
                          <p className="mb-2">Email Info </p>{" "}
                          <Link
                            className="fs-6"
                            to={`mailto:${contactGroup.contactUsInfo?.groupContactUsemail}`}
                          >
                            {contactGroup.contactUsInfo?.groupContactUsemail}
                          </Link>
                        </div>
                      </div>
                      <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                        <div className="icon">
                          <span className="flaticon-location-pin"></span>
                        </div>
                        <div className="text">
                          <p className="mb-2">Address </p>{" "}
                          <Link to="#." className="fs-6">
                            {contactGroup.contactUsInfo?.groupContactUsaddress}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="col-12 col-lg-12 d-flex flex-wrap mx-auto   justify-content-around  align-items-center ">
                {contactGroup.hotelsContact?.map((contact, index) => (
                  <div
                    className="contact-box col-12 col-lg-5 mb-3 mt-3 mt-lg-0 mb-lg-0 "
                    key={index}
                  >
                    <iframe
                      className="w-100"
                      height="350"
                      title={`${contact.hotelName} location`}
                      src={contact.hotelGoogleMap}
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                    />
                    <hr style={{ border: "1px solid #ccc" }} />
                    <h4 className="text-center">{contact.hotelName}</h4>
                    <hr style={{ border: "1px solid #ccc" }} />

                    <div className="mb-2">
                      <Link to={`tel:${contact.hotelPhone}`}>
                        <FontAwesomeIcon icon={faPhone} className="me-2" />
                        {contact.hotelPhone}
                      </Link>
                    </div>
                    <div className="mb-2">
                      <Link to={`mailto:${contact.hotelEmail}`}>
                        <FontAwesomeIcon icon={faEnvelope} className="me-2" />{" "}
                        {contact.hotelEmail}
                      </Link>
                    </div>
                    <div className="mb-2">
                      <Link to={contact.hotelGetDirectionLink} target="_blank">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="me-2"
                        />
                        {contact.hotelAddress}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ContactUsGroup;
