import React, { Fragment, useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";

import BookingForm from "../components/BookingForm";
import { Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Loader } from "rsuite";
import { fetchAbout } from "./../data/aboutSlice";
import { useDispatch, useSelector } from "react-redux";
SwiperCore.use([Pagination, Navigation]);
const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const { about, loading, error } = useSelector((state) => state.about);

  useEffect(() => {
    dispatch(fetchAbout());
  }, [dispatch, hotelUrl]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{about.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={about.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={about.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? about.pageDetails?.pageBannerMobile
                  : isTablet
                  ? about.pageDetails?.pageBannerTablet
                  : about.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <h1>{about.pageDetails?.pageMetatagTitle}</h1>
                </div>
              </div>
            </div>
          </section>{" "}
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {about.pageDetails?.pageMetatagTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="pt-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <div className="text-15 uppercase  ">
                    <img
                      src={about?.groupLogoColored}
                      style={{ width: "auto" }}
                      alt={about.pageDetails?.pageMetatagTitle}
                      className="mb-2"
                    />
                  </div>

                  <h2 className="text-50 lg:text-40 md:text-30">
                    {about.pageDetails?.pageTitle}
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            {" "}
            <hr style={{ borderColor: "#ccc" }} className="my-2" />
          </div>
          <section className="pricing  pb-5 mb-5 pt-0">
            <div className="container">
              <div className="row">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${about.pageDetails?.pageText}`,
                  }}
                />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AboutUs;
