import React, { useEffect } from "react";

// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Bounce, Fade, Zoom, Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchPrivacy } from "../../data/privacySlice";
import Loader from "../../Loader";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { privacy, loading, error } = useSelector((state) => state.privacy);
  useEffect(() => {
    dispatch(fetchPrivacy());
  }, [dispatch, hotelUrl]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{privacy.pageMetatagTitle}</title>

            <meta name="description" content={privacy.pageMetatagDescription} />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={privacy.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? privacy.pageBannerMobile
                  : isTablet
                  ? privacy.pageBannerTablet
                  : privacy.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{privacy.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>

          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1 mb-5"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to="/"
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {privacy?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="first-section mt-50 " id="terms">
            <Container>
              <div className="page_content_wrap scheme_default">
                <div
                  className="content_wrap pt-50 "
                  dangerouslySetInnerHTML={{
                    __html: `${privacy.pageText}`,
                  }}
                />
              </div>
            </Container>
          </section>
        </>
      )}
    </>
  );
};
export default PrivacyPolicy;
