import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccommodationDetails } from "./../../data/accommodation/accommodationDetailsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faMaximize } from "@fortawesome/free-solid-svg-icons";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const AccommodationDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const { roomUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { accommodationDetails, loading, error } = useSelector(
    (state) => state.accommodationDetails
  );
  useEffect(() => {
    dispatch(fetchAccommodationDetails({ hotelUrl, roomUrl }));
  }, [dispatch, roomUrl, hotelUrl]);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  const moreOptions = {
    loop: false,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{accommodationDetails.metatagTitle}</title>
            <meta
              name="description"
              content={accommodationDetails.metatagDescription}
            />
            <style>{`#news .item img {
    width: 365px;
    height: 300px;
}
            `}</style>
            <link
              rel="stylesheet"
              property="stylesheet"
              href="//websdk.fastbooking-services.com/widgets/app.css"
            />
            <script
              type="text/javascript"
              src="//websdk.fastbooking-services.com/widgets/app.js"
            ></script>
            <style>{`
          .starting-from {
            display: flex !important;
            align-items: center !important;
            justify-content: space-around !important;
            max-width: 1000px !important;
            margin: 0 auto !important;
            padding: 1px !important;
            font-size: 13px !important;
            border: 0px solid #000 !important;
          }
          .starting-from .starting-from--price {
            font-size: 20px !important;
            color: #b0a06c !important;
          }
          .show-modal[data-v-05537ca1] {
            cursor: pointer;
            display: inline-block;
            line-height: 20px;
            min-width: 100px;
            padding: 9px 10px !important;
            text-align: center;
            vertical-align: middle;
          }
          .best-price .day--price {
            color: #008a00 !important;
            font-size: 10px !important;
          }
        `}</style>
          </Helmet>
          <header className="header slider details">
            <OwlCarousel className="owl-theme" {...options}>
              {accommodationDetails.roomsGalleries?.map((image, index) => (
                <div
                  key={index}
                  className="item bg-img text-center"
                  style={{
                    backgroundImage: `url(${image.photoFile})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              ))}
            </OwlCarousel>
            <div className="arrow bounce text-center">
              <Link to="#." onClick={(e) => scrollToSection("content")}>
                {" "}
                <i className="ti-arrow-down"></i>{" "}
              </Link>
            </div>
            <div className="owl-dots">
              <button role="button" className="owl-dot active">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
            </div>
          </header>
          <div
            style={{ marginTop: "-28px" }}
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/accommodation`}
                >
                  Accommodation
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {accommodationDetails.roomName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="rooms-page py-5 " id="content">
            <div className="container">
              <div className="row ">
                <div className="col-md-8 pe-5">
                  <Fade cascade direction="up" duration="500" triggerOnce>
                    {" "}
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <div className="section-subtitle">
                      {accommodationDetails.roomTypeCategoryName}
                    </div>
                    <div className="section-title mb-0">
                      {accommodationDetails.roomName}{" "}
                    </div>
                    <p className="section-subtitle text-start d-flex">
                      {" "}
                      <div
                        id={`fb-widget-0`}
                        className="fb-widget"
                        data-fbConfig="0"
                      ></div>
                      <script
                        className="fb-widget-config"
                        data-fbConfig="0"
                        type="application/json"
                      >
                        {JSON.stringify({
                          params: [
                            {
                              startingFrom: {
                                theme: "sf--thm-1",
                                call2action: "none",
                                roomRateFiltering: 2,
                                rateFilter: [],
                                roomFilter: [accommodationDetails.roomPmscode],
                                dayRange: 180,
                              },
                              currency: "USD",
                              locale: "en_GB",
                              pricesDisplayMode: "normal",
                              offerComputeRule: "lowerMinstay",
                              maxAdults: 3,
                              maxChildren: 2,
                              mainColor: "#b0a06c",
                              themeDark: false,
                              openFrontInNewTab: true,
                              property: accommodationDetails.hotelPmscode,
                              title: accommodationDetails.hotelName,
                              childrenMaxAge: 12,
                              groupsNames: "egstellamakadihotels",
                              quicksearch: {
                                showChildrenAges: true,
                              },
                              fbWidget: "StartingFrom",
                            },
                          ],
                          commonParams: {
                            redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                            showPropertiesList: false,
                            demoMode: false,
                            allowGroupSelection: false,
                            groupName: "",
                          },
                          _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                          propertyIndex: 0,
                          version: "1.52.0",
                          baseHost: `websdk.fastbooking-services.com`,
                        })}
                      </script>
                    </p>
                    <hr style={{ borderColor: "#555" }} />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${accommodationDetails.roomDetails}`,
                      }}
                    />
                    <div className=" my-3">
                      {accommodationDetails.roomSize && (
                        <div className="d-flex w-auto   align-items-center text-dark">
                          <b className="me-2">
                            <FontAwesomeIcon
                              icon={faMaximize}
                              className="me-2"
                            />
                            Size:
                          </b>
                          {accommodationDetails.roomSize}
                        </div>
                      )}
                      {accommodationDetails.maxOccupancy && (
                        <div className="d-flex w-auto   align-items-center text-dark">
                          <b className="me-2">
                            <FontAwesomeIcon icon={faUser} className="me-2" />{" "}
                            Max:
                          </b>
                          {accommodationDetails.maxOccupancy}
                        </div>
                      )}

                      {accommodationDetails.roomView && (
                        <div className="d-flex w-auto   align-items-center text-dark">
                          <b className="me-2">
                            <FontAwesomeIcon icon={faEye} className="me-2" />{" "}
                            View:
                          </b>
                          {accommodationDetails.roomView}
                        </div>
                      )}
                      {accommodationDetails.roomBed && (
                        <div className="d-flex w-auto   align-items-center text-dark">
                          <b className="me-2">
                            <FontAwesomeIcon icon={faBed} className="me-2" />{" "}
                            Bed:
                          </b>
                          {accommodationDetails.roomBed}
                        </div>
                      )}
                    </div>
                  </Fade>
                </div>

                <div
                  className="col-md-4  "
                  style={{
                    height: "100%",
                    boxShadow: "0 0 5px #ccc",
                    padding: "20px",
                  }}
                >
                  <h6>Amenities </h6>
                  <ul className="list-unstyled page-list mb-30">
                    {accommodationDetails.roomAmenities?.map(
                      (amenity, index) => (
                        <li className="amenities " key={index}>
                          <img
                            className="amenities-icon"
                            alt={amenity.roomAmenitiesName}
                            src={amenity.roomAmenitiesPhoto}
                          />
                          {amenity.roomAmenitiesName}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <hr style={{ borderColor: "#555" }} />
          <section className="rooms1 accommodation   py-5 mb-5 " id="news">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-subtitle">
                    <span>Explore</span>
                  </div>
                  <Fade direction="left" triggerOnce="true">
                    <div className="section-title">
                      <span className="mb-3  text-dark">
                        Other Accommodation
                      </span>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel className="owl-theme" {...moreOptions}>
                    {accommodationDetails.otherRooms?.map((room, index) => (
                      <div className="item" key={room.roomUrl}>
                        <div className="position-re o-hidden">
                          {" "}
                          <img src={room.roomPhoto} alt={room.roomName} />
                        </div>{" "}
                        <div className="con">
                          <h5>
                            <Link
                              to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                            >
                              {room.roomName}
                            </Link>
                          </h5>
                          <h6>
                            <Link
                              to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                            >
                              {room.roomTypeCategoryName}
                            </Link>
                          </h6>
                          <div className="row my-1">
                            <div className="d-flex flex-wrap row">
                              {room.roomSize && (
                                <div className="d-flex w-auto   align-items-center text-light">
                                  <FontAwesomeIcon
                                    icon={faMaximize}
                                    className="me-2"
                                  />
                                  Size: {room.roomSize}
                                </div>
                              )}
                              {room.maxOccupancy && (
                                <div className="d-flex w-auto   align-items-center text-light">
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="me-2"
                                  />
                                  Max Occupancy: {room.maxOccupancy}
                                </div>
                              )}

                              {room.roomView && (
                                <div className="d-flex w-auto   align-items-center text-light">
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="me-2"
                                  />

                                  {room.roomView}
                                </div>
                              )}
                              {room.roomBed && (
                                <div className="d-flex w-auto   align-items-center text-light">
                                  <FontAwesomeIcon
                                    icon={faBed}
                                    className="me-2"
                                  />

                                  {room.roomBed}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="permalink my-2   text-end row">
                            <Link
                              style={{ border: `1px solid` }}
                              to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                              className="text-center d-block w-auto"
                            >
                              Details <i className="ti-arrow-right"></i>
                            </Link>
                          </div>
                          <div className="line mb-0"></div>
                          <div className="row facilities">
                            {/* <div className="col col-md-12">
                              <p>{room.roomSummery}</p>
                            </div> */}
                            <div className="col col-12  text-end  ">
                              <div
                                id={`fb-widget-${index}`}
                                className="fb-widget"
                                data-fbConfig={index}
                              ></div>
                              <script
                                className="fb-widget-config"
                                data-fbConfig={index}
                                type="application/json"
                              >
                                {JSON.stringify({
                                  params: [
                                    {
                                      startingFrom: {
                                        theme: "sf--thm-1",
                                        call2action: "none",
                                        roomRateFiltering: 2,
                                        rateFilter: [],
                                        roomFilter: [room.roomPmscode],
                                        dayRange: 180,
                                      },
                                      currency: "USD",
                                      locale: "en_GB",
                                      pricesDisplayMode: "normal",
                                      offerComputeRule: "lowerMinstay",
                                      maxAdults: 3,
                                      maxChildren: 2,
                                      mainColor: "#b0a06c",
                                      themeDark: false,
                                      openFrontInNewTab: true,
                                      property:
                                        accommodationDetails.hotelPmscode,
                                      title: accommodationDetails.hotelName,
                                      childrenMaxAge: 12,
                                      groupsNames: "egstellamakadihotels",
                                      quicksearch: {
                                        showChildrenAges: true,
                                      },
                                      fbWidget: "StartingFrom",
                                    },
                                  ],
                                  commonParams: {
                                    redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                    showPropertiesList: false,
                                    demoMode: false,
                                    allowGroupSelection: false,
                                    groupName: "",
                                  },
                                  _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                  propertyIndex: 0,
                                  version: "1.52.0",
                                  baseHost: `websdk.fastbooking-services.com`,
                                })}
                              </script>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default AccommodationDetails;
