import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingForm from "../../components/BookingForm";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faUserCheck,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { fetchAllInclusive } from "../../data/all-inclusive/allInclusiveSlice";
const AllInclusive = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { allInclusive, loading, error } = useSelector(
    (state) => state.allInclusive
  );
  useEffect(() => {
    if (loading === true) {
      dispatch(fetchAllInclusive(hotelUrl));
    }
  }, [loading, dispatch, hotelUrl]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{allInclusive.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={allInclusive.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={
              allInclusive.pageDetails?.pageBannerColorOverlayTo
            }
            style={{
              backgroundImage: `url(${
                isMobile
                  ? allInclusive.pageDetails.pageBannerMobile
                  : isTablet
                  ? allInclusive.pageDetails.pageBannerTablet
                  : allInclusive.pageDetails.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade cascade direction="up">
                    {" "}
                    <h1>{allInclusive.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {allInclusive.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-md-8 pe-5">
                  <Fade cascade direction="up" triggerOnce>
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${allInclusive.pageDetails?.pageText}`,
                      }}
                    />
                  </Fade>
                </div>
                <div
                  className="col-md-4  "
                  style={{
                    height: "100%",
                    boxShadow: "0 0 5px #ccc",
                    padding: "20px",
                  }}
                >
                  <h2>Dining </h2>
                  <ul className="list-unstyled page-list mb-30">
                    {allInclusive.restauransList?.map((restaurant, index) => (
                      <li className="amenities " key={index}>
                        <img
                          className="  me-3"
                          style={{ borderRadius: "5px", width: "20%" }}
                          alt={restaurant.restaurantName}
                          src={restaurant.restaurantPhoto}
                        />
                        <Link
                          to={`/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`}
                        >
                          {restaurant.restaurantName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AllInclusive;
