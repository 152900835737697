import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccommodationDetails } from "../../data/accommodation/accommodationDetailsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faMaximize,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const SpaDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const { roomUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { accommodationDetails, loading, error } = useSelector(
    (state) => state.accommodationDetails
  );
  useEffect(() => {
    dispatch(fetchAccommodationDetails({ hotelUrl, roomUrl }));
  }, [dispatch, roomUrl, hotelUrl]);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  const moreOptions = {
    loop: false,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [openPanels, setOpenPanels] = useState([]);
  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };
  const faqData = [
    {
      question: "Do I have to pay extra fees?",
      answer: "Response to question about fees.",
    },
    {
      question: "Can I change my reservation?",
      answer: "Response to question about changing reservation.",
    },
    {
      question: "What happens if a reservation cancels?",
      answer: "Response to cancellation scenario.",
    },
    // Add more questions and answers as needed
  ];

  return (
    <>
      <header className="header slider details">
        <OwlCarousel className="owl-theme" {...options}>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: `url(img/spa/1.jpg)`,
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: `url(img/spa/2.jpg)`,
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: `url(img/spa/3.jpg)`,
              backgroundSize: "cover",
            }}
          ></div>
        </OwlCarousel>

        <div className="arrow bounce text-center">
          <Link to="#." onClick={(e) => scrollToSection("content")}>
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </Link>
        </div>
        <div className="owl-dots">
          <button role="button" className="owl-dot active">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
        </div>
      </header>
      <BookingForm />
      <div role="presentation" className="breadcrumbs-wrapper py-3 bg-light-1">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
            <Link
              className="d-flex align-items-center"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to={`/`}
            >
              <HomeIcon
                sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                fontSize="inherit"
              />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}`}
            >
              {hotelUrl === "stella-beach-resort-spa"
                ? "Stella Beach Resort & Spa "
                : "Stella Gardens Resort & Spa"}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}/spa`}
            >
              Spa
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Spa Details
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <section className="rooms-page py-5 " id="content">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 pe-5">
              <Fade cascade direction="up" duration="700" triggerOnce>
                {" "}
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <div className="section-subtitle">SPA Category</div>
                <div className="section-title">SPA</div>
                <hr style={{ borderColor: "#555" }} />
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Incidunt, fugiat! Nam aut voluptatum cum assumenda? Laudantium
                  odit aliquam debitis, ex deserunt iste quod? Ex commodi
                  pariatur ducimus quisquam atque quidem?
                </p>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Incidunt, fugiat! Nam aut voluptatum cum assumenda? Laudantium
                  odit aliquam debitis, ex deserunt iste quod? Ex commodi
                  pariatur ducimus quisquam atque quidem?
                </p>
              </Fade>
            </div>

            <div
              className="col-md-4  "
              style={{
                height: "100%",
                boxShadow: "0 0 5px #ccc",
                padding: "20px",
              }}
            >
              <h6>Other </h6>
              <ul className="list-unstyled page-list mb-30">
                <li className="amenities ">
                  <Link to="#."> Spa Title 01</Link>
                </li>
                <li className="amenities ">
                  <Link to="#."> Spa Title 02</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ borderColor: "#555" }} />
      <section className="py-5 mb-5">
        <div className="container">
          <div className="row mx-auto justify-content-center">
            <div class="col-md-12">
              <div class="section-subtitle">
                <b>Services</b>{" "}
              </div>
              <div class="section-title">Spa Etiquette </div>
            </div>
            <div className="col-xl-12  col-lg-12">
              <div className="accordion -type-1 row y-gap-10">
                {faqData?.map((item, index) => (
                  <div key={index} className="col-12">
                    <div className="accordion__item">
                      <div
                        className="accordion__button d-flex align-baseline justify-between px-30 py-20 bg-light-1"
                        onClick={() => togglePanel(index)}
                      >
                        <div className="text-sec text-24 fw-500 lh-1">
                          {item.question}
                        </div>
                        <div className="accordion__icon">
                          {openPanels.includes(index) ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </div>
                      </div>
                      {openPanels.includes(index) && (
                        <div className="accordion__content">
                          <div className="px-30 py-30">
                            <h2>
                              <b>Service Type (Duration)</b>
                            </h2>
                            <p className="text-dark">{item.answer}</p>
                            <h2>
                              <b>Service Type (Duration)</b>
                            </h2>
                            <p className="text-dark">{item.answer}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SpaDetails;
