import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";

SwiperCore.use([Pagination, Navigation]);
const Offer = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  return (
    <>
      <section
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="3"
        style={{ backgroundImage: 'url("img/slider/5.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left caption mt-90">
              <h1>Special Offer</h1>
            </div>
          </div>
        </div>
      </section>
      <BookingForm />
      <section className="py-5 mt-5">
        <div className="container">
          <div className="row  mx-auto justify-content-center text-center">
            <div className="col-lg-10">
              <div className="text-15 uppercase mb-20">Orient Hotels</div>
              <h2 className="text-50 lg:text-40 md:text-30">Special Offers</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing  pb-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="pricing-card">
                <img src="img/pricing/1.jpg" alt="" />
                <div className="desc">
                  <div className="name">Room cleaning </div>
                  <div className="amount">
                    $50 <span>/ month </span>
                  </div>
                  <ul className="list-unstyled list">
                    <li>
                      <i className="ti-check"></i> Hotel ut nisan duru{" "}
                    </li>
                    <li>
                      <i className="ti-check"></i> Orci miss natoque ince{" "}
                    </li>
                    <li>
                      <i className="ti-close unavailable"></i>Clean sorem ipsum
                      morbin{" "}
                    </li>
                  </ul>
                </div>
                <div className="form-group ">
                  <input
                    type="submit"
                    className="d-block w-100"
                    value="Learn More"
                    onClick={() => navigate("offer-details")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-card">
                <img src="img/pricing/2.jpg" alt="" />
                <div className="desc">
                  <div className="name">Drinks included </div>
                  <div className="amount">
                    $30 <span>/ daily </span>
                  </div>
                  <ul className="list-unstyled list">
                    <li>
                      <i className="ti-check"></i> Hotel ut nisan duru{" "}
                    </li>
                    <li>
                      <i className="ti-check"></i> Orci miss natoque ince{" "}
                    </li>
                    <li>
                      <i className="ti-close unavailable"></i>Clean sorem ipsum
                      morbin{" "}
                    </li>
                  </ul>
                </div>
                <div className="form-group ">
                  <input
                    type="submit"
                    className="d-block w-100"
                    value="Learn More"
                    onClick={() => navigate("offer-details")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-card">
                <img src="img/pricing/3.jpg" alt="" />
                <div className="desc">
                  <div className="name">Room Breakfast </div>
                  <div className="amount">
                    $30 <span>/ daily </span>
                  </div>
                  <ul className="list-unstyled list">
                    <li>
                      <i className="ti-check"></i> Hotel ut nisan duru{" "}
                    </li>
                    <li>
                      <i className="ti-check"></i> Orci miss natoque ince{" "}
                    </li>
                    <li>
                      <i className="ti-close unavailable"></i>Clean sorem ipsum
                      morbin{" "}
                    </li>
                  </ul>
                </div>
                <div className="form-group ">
                  <input
                    type="submit"
                    className="d-block w-100"
                    value="Learn More"
                    onClick={() => navigate("offer-details")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offer;
