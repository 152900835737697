import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchFacilityDetails } from "./../../data/facilities/facilityDetailsSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const FacilitiesDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const { facilityUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { facilityDetails, loading, error } = useSelector(
    (state) => state.facilityDetails
  );

  useEffect(() => {
    dispatch(fetchFacilityDetails({ hotelUrl, facilityUrl }));
  }, [dispatch, facilityUrl, hotelUrl]);
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  const moreOptions = {
    loop: false,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{facilityDetails.metatagTitle}</title>
            <meta
              name="description"
              content={facilityDetails.metatagDescription}
            />
          </Helmet>
          <header className="header slider details">
            <OwlCarousel className="owl-theme" {...options}>
              {facilityDetails.facilityGallery?.map((image, index) => (
                <div
                  key={index}
                  className="item bg-img text-center"
                  style={{
                    backgroundImage: `url(${image.photoFile})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              ))}
            </OwlCarousel>
            <div className="arrow bounce text-center">
              <Link to="#." onClick={(e) => scrollToSection("content")}>
                {" "}
                <i className="ti-arrow-down"></i>{" "}
              </Link>
            </div>
            <div className="owl-dots">
              <button role="button" className="owl-dot active">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
            </div>
          </header>
          <div
            style={{ marginTop: "-28px" }}
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/facilities`}
                >
                  Facilities
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {facilityDetails.facilityName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />

          <section className="rooms-page py-5 " id="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left">
                  <Fade triggerOnce cascade direction="up">
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>

                    <div className="section-title">
                      {facilityDetails.facilityName}
                    </div>
                  </Fade>
                </div>
                <div className="col-md-12">
                  <p
                    className="mb-30"
                    dangerouslySetInnerHTML={{
                      __html: `${facilityDetails.facilityDetails}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <hr style={{ borderColor: "#555" }} />
          <section className="rooms1 rooms1   py-5 mb-5 new" id="news">
            <div className="container">
              <div className="row">
                <Fade triggerOnce direction="left">
                  <div className="col-md-12">
                    <div className="section-subtitle">
                      <span>Explore</span>
                    </div>
                    <div className="section-title">
                      <span className="  text-dark">Other Facilities</span>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel className="owl-theme" {...moreOptions}>
                    {facilityDetails.otherFacility?.map((item) => (
                      <div className="item" key={item.facilityUrl}>
                        <div className="position-re o-hidden">
                          {" "}
                          <img
                            src={item.facilityPhoto}
                            alt={item.facilityName}
                          />{" "}
                        </div>{" "}
                        <div className="con">
                          <h5>
                            <Link
                              to={`${
                                item.isMoreDetails
                                  ? `/${item.hotelUrl}/facilities/${item.facilityUrl}`
                                  : "#."
                              }`}
                            >
                              {item.facilityName}
                            </Link>
                          </h5>
                          <div className="line"></div>
                          <div className="row facilities">
                            <div
                              className="col col-md-12 text-start"
                              style={{
                                visibility: !item.isMoreDetails && "hidden",
                              }}
                            >
                              <div className="permalink">
                                <Link
                                  to={`/${item.hotelUrl}/facilities/${item.facilityUrl}`}
                                >
                                  Details <i className="ti-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default FacilitiesDetails;
