import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop.jsx";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-image-lightbox/style.css";
import "./App.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import LayoutGroup from "./components/LayoutGroup.jsx";
import HomeGroup from "./pages/HomeGroup.jsx";
import ContactUsGroup from "./pages/contact-us/ContactUsGroup.jsx";
import ContactUsHotels from "./pages/contact-us/ContactUsHotels.jsx";
import HomeHotel from "./pages/HomeHotel.jsx";
import Accommodation from "./pages/accommodation/Accommodation.jsx";
import AccommodationDetails from "./pages/accommodation/AccommodationDetails.jsx";
import Dining from "./pages/dining/Dining.jsx";
import DiningDetails from "./pages/dining/DiningDetails.jsx";
import Facilities from "./pages/facilities/Facilities.jsx";
import FacilitiesDetails from "./pages/facilities/FacilitiesDetails.jsx";
import MeetingAndEvents from "./pages/meeting-events/MeetingAndEvents.jsx";
import MeetingAndEventsDetails from "./pages/meeting-events/MeetingAndEventsDetails.jsx";
import OnlineCheckIn from "./pages/online-check-in/OnlineCheckIn.jsx";
import Faq from "./pages/faq/Faq.jsx";
import Gallery from "./pages/gallery/Gallery.jsx";
import Offer from "./pages/offer/Offer.jsx";
import OfferDetails from "./pages/offer/OfferDetails.jsx";
import LayoutHotels from "./components/LayoutHotels.jsx";
import { fetchHotels } from "./data/hotelsSlice.js";
import { fetchProperties } from "./data/bookingEngingSlice.js";
import DiningType from "./pages/dining/DiningType.jsx";
import Cruises from "./pages/cruises/Cruises.jsx";
import Spa from "./pages/spa/Spa";
import SpaDetails from "./pages/spa/SpaDetails.jsx";
import OffersGroup from "./pages/offer/OffersGroup.jsx";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import AllInclusive from "./pages/dining/AllInclusive.jsx";
import FaqHotels from "./pages/faq/FaqHotels.jsx";
import AboutUs from "./pages/AboutUs.jsx";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHotels());

    dispatch(fetchProperties());
  }, [dispatch]);
  useEffect(() => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Check if the pathname includes '/en'
    if (!currentUrl.pathname.includes("/en")) {
      // Redirect by adding '/en' right after the domain
      window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
    }

    if (currentUrl.pathname.includes("/stella-beach-resort-spa")) {
      localStorage.setItem("hotelUrl", "stella-beach-resort-spa");
    } else if (currentUrl.pathname.includes("/stella-gardens-resort-spa")) {
      localStorage.setItem("hotelUrl", "stella-gardens-resort-spa");
    } else {
      localStorage.removeItem("hotelUrl");
    }
  }, []);

  return (
    <>
      <Router basename="/en">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LayoutGroup />}>
            <Route index element={<HomeGroup />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="online-check-in" element={<OnlineCheckIn />} />
            <Route path="contact-us" element={<ContactUsGroup />} />
            <Route path="cruises" element={<Cruises />} />
            <Route path="offer" element={<OffersGroup />} />
            <Route path="faq" element={<Faq />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
          </Route>
          <Route path="/:hotelUrl" element={<LayoutHotels />}>
            <Route index element={<HomeHotel />} />
            <Route path="contact-us" element={<ContactUsHotels />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="accommodation" element={<Accommodation />} />
            <Route
              path="accommodation/:roomUrl"
              element={<AccommodationDetails />}
            />{" "}
            <Route path="dining" element={<Dining />} />
            <Route path="all-inclusive" element={<AllInclusive />} />
            <Route path=":filterBy" element={<DiningType />} />
            <Route
              path="dining/:restaurantUrl"
              element={<DiningDetails />}
            />{" "}
            <Route path="facilities" element={<Facilities />} />
            <Route
              path="facilities/:facilityUrl"
              element={<FacilitiesDetails />}
            />{" "}
            <Route path="spa" element={<Spa />} />
            <Route path="spa/spa-details" element={<SpaDetails />} />
            <Route path="offer" element={<Offer />} />
            <Route path="offer/offer-details" element={<OfferDetails />} />
            <Route
              path="meetingandevents"
              element={<MeetingAndEvents />}
            />{" "}
            <Route
              path="meetingandevents/:facilityUrl"
              element={<MeetingAndEventsDetails />}
            />
            <Route path="faq" element={<FaqHotels />} />
            <Route
              path="*"
              element={
                <section
                  style={{
                    backgroundColor: "#293839",
                    padding: "200px 0 100px",
                  }}
                >
                  <h1 className="text-center text-light">Page Not Found</h1>
                </section>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <section
                style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
              >
                <h1 className="text-center text-light">Page Not Found</h1>
              </section>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
